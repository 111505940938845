import React from 'react';
import {
  Button
} from '@material-ui/core';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const PasoCinco = (props) => {

  const quote = props.propQuote;

  return (
      <div className="col-12 col-lg-11">
        <div className="row w-100 mx-0 justify-content-center">
          <div className="col-12 px-0 pb-5">
            <span className="tittle_">
              Esta es tu cotización
            </span>
          </div>
          <div className="col-12 px-0">
            <div className="row w-100 m-0 justify-content-center align-items-center">
              <div className="col-12 col-md-6 item_info">
                <span>Número de cotización</span>
                <span>{quote.idpublic}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Fecha</span>
                <span>{quote.fecha}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Nombre</span>
                <span>{quote.nombre}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Teléfono</span>
                <span>{quote.telefono}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Correo</span>
                <span>{quote.email}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Origen</span>
                <span>{quote.origen}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Destino</span>
                <span>{quote.destino}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Distancia</span>
                <span>{quote.distancia} km</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Metros cuadrados utilizados</span>
                <span>{quote.mts} m<sup>2</sup></span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Precio (sin IVA)</span>
                <span className="text-red fw_700">{Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(quote.precio)}</span>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p>
              <small>La presente cotización unicamente incluye el costo de transportación y maniobra de carga y descarga.</small>
            </p>
            <p>
              <small>SON COSTOS ADICIONALES A LOS ANTES MENCIONADOS: Las voladuras, empaque de cajas o muebles, estadias, almacenajes, acomodo dentro de gavetas y/o cajones, acomodo de cristaleria, colgado de cuadros ó espejos, armes y desarmes complejos (Que necesiten de una herramienta especial), desempaque de cajas que hayan sido empacadas por el cliente, no desempotramos muebles que se encuentren incrustados en la pared, asi también, no realizamos trabajos de plomería, jardinería, albañilería, carpintería o eléctricos; Tampoco están considerados en nuestros costos, accessos dificiles como son: acarreos o desplazamientos de mas de 20mts, traspaleo de carga, impedimientos para la entrada de nuestra unidad a privadas o fraccionamientos debido a cables, arcos, rejas, bardas, calles estrechas, etc.</small>
            </p>
          </div>
          <div className="col-12 pt-2 pb-4 text-center">
            <a
              className="btn_cs01"
              href={`${process.env.REACT_APP_API_URL}`+'storage/quote_'+quote.id+'.pdf'}
              target="_blank"
            >
              Imprimir cotización
            </a>
          </div>

        </div>
      </div>
  );

};

export default PasoCinco;