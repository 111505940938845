import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { logedServices } from '../../services/logedServices';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Results = ({ className, ...rest }) => {
  const [selectedInventariosIds, setSelectedInventariosIds] = useState([]);
  const [inventarios, setInventarios] = useState(null);
  const [categorias, setCategorias] = useState(null);
  const [inventarioActivo, setInventarioActivo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  
  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };


  function handleRemove(id) {
    const newList = inventarios.data.filter((item) => {
      if (parseInt(item.id) !== parseInt(id)) {
        return item;
      }
    });
    const newArray = inventarios;
    newArray['data']=newList;
    setInventarios(newArray);
  }

  function confimDelete(){
      logedServices.deleteInventario(inventarioActivo.id)
      .then(response => {
        if (response.status===200) {
          handleRemove(response.data.id);
          setOpen(false);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  useEffect(() => {
      getInventario(1);
      getCategoriasNoPage();
  }, []);

  function getInventario(page){
      logedServices.getInventario(page, 10)
      .then(response => {
        if (response.status===200) {
          setInventarios(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getCategoriasNoPage(){
      logedServices.getCategoriasNoPage()
      .then(response => {
        if (response.status===201) {
          setCategorias(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedInventariosIds;

    if (event.target.checked) {
      newSelectedInventariosIds = inventarios.data.map((inventario) => inventario.id);
    } else {
      newSelectedInventariosIds = [];
    }

    setSelectedInventariosIds(newSelectedInventariosIds);
    console.log('newSelectedInventariosIds==>')
    console.log(newSelectedInventariosIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedInventariosIds.indexOf(id);
    let newSelectedInventariosIds = [];

    if (selectedIndex === -1) {
      newSelectedInventariosIds = newSelectedInventariosIds.concat(selectedInventariosIds, id);
    } else if (selectedIndex === 0) {
      newSelectedInventariosIds = newSelectedInventariosIds.concat(selectedInventariosIds.slice(1));
    } else if (selectedIndex === selectedInventariosIds.length - 1) {
      newSelectedInventariosIds = newSelectedInventariosIds.concat(selectedInventariosIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedInventariosIds = newSelectedInventariosIds.concat(
        selectedInventariosIds.slice(0, selectedIndex),
        selectedInventariosIds.slice(selectedIndex + 1)
      );
    }

    setSelectedInventariosIds(newSelectedInventariosIds);
  };

  const handlePageChange = (event, newPage) => {
    getInventario(newPage);
  };

  return (
    <React.Fragment>
      <Card>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <CardHeader
              title="Inventario"
            />
            <Grid container justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{marginRight: "15px", marginBottom: "15px"}}
                onClick={() => { setOpenAdd(true); }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          {inventarios!==null && inventarios.data.length>0 ? 
          <Grid
            item
            xs={12}
          >
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <PerfectScrollbar>
                    <Box minWidth={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedInventariosIds.length === inventarios.data.length}
                                color="primary"
                                indeterminate={
                                  selectedInventariosIds.length > 0
                                  && selectedInventariosIds.length < inventarios.data.length
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell align="center">
                              Nombre
                            </TableCell>
                            <TableCell align="center">
                              Metros cubicos
                            </TableCell>
                            <TableCell align="center">
                              Categoría
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {inventarios.data.map((inventario) => (
                            <TableRow
                              hover
                              key={inventario.id}
                              selected={selectedInventariosIds.indexOf(inventario.id) !== -1}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedInventariosIds.indexOf(inventario.id) !== -1}
                                  onChange={(event) => handleSelectOne(event, inventario.id)}
                                  value="true"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {inventario.nombre}
                              </TableCell>
                              <TableCell align="center">
                                {inventario.mts}
                              </TableCell>
                              <TableCell align="center">
                                {inventario.categoria.nombre}
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  <Button onClick={() => { setInventarioActivo(inventario);setOpenUpdate(true); }}>Editar</Button>
                                  <Button onClick={() => { setInventarioActivo(inventario);setOpen(true); }}>Eliminar</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid container justifyContent="flex-end">
                    <Pagination count={inventarios.meta.last_page} page={inventarios.meta.current_page} onChange={handlePageChange} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          : ''}
        </Grid>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se eliminara el elemento '{inventarioActivo!==null ? inventarioActivo.nombre : ''}''
            <strong className="block_ctrl">¿Deseas continuar?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { confimDelete(); }} color="primary">
            Si, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAdd} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            categoria_id: '',
            nombre: '',
            mts: ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            categoria_id: Yup.string().required(),
            nombre: Yup.string().max(100).required(),
            mts: Yup.number("Must be number").required().positive().min(0, "Min is 0").max(22, "max is 20"),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.addInventario(values)
              .then(response => {
                if (response.status === 201) {
                  getInventario(1);
                  setOpenAdd(false);
                }
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Nuevo elemento al inventario"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            {categorias.length>0 ? 
                              <TextField
                                select
                                error={Boolean(touched.categoria_id && errors.categoria_id)}
                                fullWidth
                                helperText={touched.categoria_id && errors.categoria_id}
                                label="Categoría"
                                margin="normal"
                                name="categoria_id"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.categoria_id}
                                variant="outlined"
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option key={999} value="">
                                </option>
                                {categorias.map((categoria) => 
                                  {
                                    return(
                                      <option key={categoria.id} value={categoria.id}>
                                        {categoria.nombre}
                                      </option>
                                    );
                                  }
                                )}
                              </TextField>
                            :''}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              type="number"
                              error={Boolean(touched.mts && errors.mts)}
                              fullWidth
                              helperText={touched.mts && errors.mts}
                              label="Metros cubicos"
                              margin="normal"
                              name="mts"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mts}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAdd} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Agregar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>


      <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            id: inventarioActivo!==null ? inventarioActivo.id : '',
            categoria_id: inventarioActivo!==null ? inventarioActivo.categoria.id : '',
            nombre: inventarioActivo!==null ? inventarioActivo.nombre : '',
            mts: inventarioActivo!==null ? inventarioActivo.mts : ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            categoria_id: Yup.string().required(),
            nombre: Yup.string().max(100).required(),
            mts: Yup.number("Must be number").required().positive().min(0, "Min is 0").max(22, "max is 20"),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            logedServices.updateInventario(values)
              .then(response => {
                if (response.status === 200) {
                  getInventario(1);
                  setOpenUpdate(false);
                }
              })
              .catch(function (error) {
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Actualizando inventario"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            {categorias.length>0 ? 
                              <TextField
                                select
                                error={Boolean(touched.categoria_id && errors.categoria_id)}
                                fullWidth
                                helperText={touched.categoria_id && errors.categoria_id}
                                label="Categoría"
                                margin="normal"
                                name="categoria_id"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.categoria_id}
                                variant="outlined"
                                SelectProps={{
                                  native: true,
                                }}
                              >
                                <option key={999} value="">
                                </option>
                                {categorias.map((categoria) => 
                                  {
                                    return(
                                      <option key={categoria.id} value={categoria.id}>
                                        {categoria.nombre}
                                      </option>
                                    );
                                  }
                                )}
                              </TextField>
                            :''}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              type="number"
                              error={Boolean(touched.mts && errors.mts)}
                              fullWidth
                              helperText={touched.mts && errors.mts}
                              label="Metros cubicos"
                              margin="normal"
                              name="mts"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.mts}
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUpdate} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Actualizar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>



    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
