import { axiosApiInstance } from './ManageToken'
import { showToastr, toastrError } from '../utils/toast';

function getUserInSession(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/user/session`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateUser(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/user/session`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu perfil.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function changePassword(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/changePassword`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu contaseña.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getCategorias(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/categorias`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getCategoriasNoPage(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/allcategorias`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addCategoria(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/categorias`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nueva categoría.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteCategoria(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/categorias/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La categoría ha sido eliminada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateCategoria(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/categorias/`+object_data.id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La categoría ha sido actualizada.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getInventario(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/inventarios`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addInventario(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/inventarios`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nuevo elemento al inventario.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteInventario(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/inventarios/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El elemento ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateInventario(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/inventarios/`+object_data.id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La elemento ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getQuotes(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/quotes`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getQuote(id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/quotes/`+id,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteQuote(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/quotes/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El elemento ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

export const logedServices = {
  getUserInSession,
  updateUser,
  changePassword,
  getCategorias,
  addCategoria,
  deleteCategoria,
  updateCategoria,
  getInventario,
  addInventario,
  getCategoriasNoPage,
  deleteInventario,
  updateInventario,
  getQuotes,
  deleteQuote,
  getQuote
};
