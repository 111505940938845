import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  Box,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Paper from '@material-ui/core/Paper';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Page from 'src/components/Page';
import { logedServices } from '../../services/logedServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Cotizacion = () => {

  const classes = useStyles();
  const { id } = useParams();
  const [quote, setQuote] = useState(null);

  useEffect(() => {
      getQuote();
  }, []);

  function getQuote(){
      logedServices.getQuote(id)
      .then(response => {
        if (response.status===200) {
          setQuote(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  return (
    <Page
      className={classes.root}
      title={'Cotización'}
    >
      <Container maxWidth={false}>
        <Card>
          <Grid
            container
            spacing={1}
          >
            <Grid
              item
              xs={12}
            >
              <h5 className="px-3 pt-3 pb-2 m-0 fw_600">Cotización</h5>
            </Grid>
            {quote!==null ? 
            <Grid
              item
              xs={12}
            >
              <Divider />
              <CardContent>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    xl={3}
                  >
                    <TableContainer component={Paper}>
                      <Table aria-label="Fecha">
                        <TableBody>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <strong>Fecha de creación</strong>
                              </TableCell>
                              <TableCell align="left">{quote.created_at}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell component="th" scope="row">
                                <strong>Precio calculado</strong>
                              </TableCell>
                              <TableCell align="left">{Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(quote.precio)}</TableCell>
                            </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={8}
                    xl={9}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      startIcon={<PictureAsPdfIcon />}
                      href={`${process.env.REACT_APP_API_URL}`+'storage/quote_'+quote.id+'.pdf'}
                      target="_blank"
                    >
                      Archivo
                    </Button>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="pb-0"
                  >
                    <h5 className="fw_600">Datos del cliente</h5>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    lg={8}
                    className="pt-0"
                  >
                    <PerfectScrollbar>
                      <Box minWidth={400}>
                        <TableContainer component={Paper}>
                          <Table className="custom_table" aria-label="Datos del cliente">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Nombre</TableCell>
                                <TableCell align="center">Telefono</TableCell>
                                <TableCell align="center">Email</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                  <TableCell align="center">{quote.nombre}</TableCell>
                                  <TableCell align="center"><a href={'tel:'+quote.telefono}>{quote.telefono}</a></TableCell>
                                  <TableCell align="center"><a href={'mailto:'+quote.email}>{quote.email}</a></TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </PerfectScrollbar>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    className="pb-0"
                  >
                    <h5 className="fw_600">Datos de la mudanza</h5>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="pt-0"
                  >
                    <PerfectScrollbar>
                      <Box minWidth={600}>
                        <TableContainer component={Paper}>
                          <Table className="custom_table" aria-label="Datos de la mudanza">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Fecha</TableCell>
                                <TableCell align="center">Origen</TableCell>
                                <TableCell align="center">Destino</TableCell>
                                <TableCell align="center">Distancia</TableCell>
                                <TableCell align="center">M<sup>2</sup></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                  <TableCell align="center">{quote.fecha}</TableCell>
                                  <TableCell align="center">{quote.origen}</TableCell>
                                  <TableCell align="center">{quote.destino}</TableCell>
                                  <TableCell align="center">{Math.round((parseFloat(quote.distancia) + Number.EPSILON) * 100) / 100}km</TableCell>
                                  <TableCell align="center">{quote.mts}m<sup>2</sup></TableCell>
                                </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </PerfectScrollbar>
                  </Grid>


                  <Grid
                    item
                    xs={12}
                    className="pb-0"
                  >
                    <h5 className="fw_600">Inventario</h5>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    lg={8}
                    className="pt-0"
                  >
                    <PerfectScrollbar>
                      <Box minWidth={450}>
                        <TableContainer component={Paper}>
                          <Table className="custom_table" aria-label="Inventario">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="center">Nombre</TableCell>
                                <TableCell align="center">M<sup>2</sup></TableCell>
                                <TableCell align="center">Cantidad</TableCell>
                                <TableCell align="center">Subtotal m<sup>2</sup></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                                {quote.inventario.map((item,value) => {
                                    return(
                                      <TableRow key={item.id}>
                                        <TableCell align="center">{value+1}</TableCell>
                                        <TableCell align="center">{item.nombre}</TableCell>
                                        <TableCell align="center">{item.mts}</TableCell>
                                        <TableCell align="center">{item.qty}</TableCell>
                                        <TableCell align="center">{item.mts+item.qty}</TableCell>
                                      </TableRow>
                                    );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </PerfectScrollbar>
                  </Grid>


                </Grid>
              </CardContent>
            </Grid>
            : ''}
          </Grid>
        </Card>


      </Container>
    </Page>
  );
};

export default Cotizacion;