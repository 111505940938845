import React, { useState } from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import logo_white from '../../static/imgs/logo_white.svg';
import logo_color from '../../static/imgs/logo.png';
import step_one from '../../static/imgs/icons-17.svg';
import step_two from '../../static/imgs/icons-18.svg';
import step_three from '../../static/imgs/icons-19.svg';
import step_five from '../../static/imgs/icons-20.svg';
import icon_1 from '../../static/imgs/icons-01.svg';
import icon_2 from '../../static/imgs/icons-02.svg';
import icon_3 from '../../static/imgs/icons-03.svg';
import icon_4 from '../../static/imgs/icons-04.svg';
import icon_5 from '../../static/imgs/icons-05.svg';
import PasoUno from './PasoUno';
import PasoDos from './PasoDos';
import PasoTres01 from './PasoTres01';
import PasoTres02 from './PasoTres02';
import PasoCuatro from './PasoCuatro';
import PasoCinco from './PasoCinco';

const Cotizar = () => {

  const [dataCotizacion, setDataCotizacion] = useState(false);
  //const [dataCotizacion, setDataCotizacion] = useState({nombre: "Jhon Doe", telefono: "4421325412", email: "bijehaxad@mailinator.com", dd: "11", mm: "09", aaaa: "2021"});
  const [distancia, setDistancia] = useState(0);
  const [origenDestino, setOrigenDestino] = useState(false);
  const [categoria, setCategoria] = useState(false);
  const [inventario, setInventario] = useState([]);
  const [totalMts, setTotalMts] = useState([]);
  const [quote, setQuote] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const [step, setStep] = useState(1);
  const nextStep = () => {
    setStep(step+1);
  };
  const prevStep = () => {
    setStep(step-1);
  };

  return (
      <div className="container-fluid h-100 w-100 main_public_layout">

        <div className={"menu_content " + (showMenu ? 'show_' : '')}>
          <button onClick={() => { setShowMenu(false); }}>
            <ArrowForwardIcon/>
          </button>
          <div>
            <div className="float_top">
              <img src={logo_color} alt=""/>
            </div>
            <div className="row w-100 h-100 justify-content-center align-items-center">
              <div className="col-auto">
                <ul>
                  <li>
                    <a href="https://mudandote.mx">Inicio</a>
                  </li>
                  <li>
                    <a href="https://mudandote.mx/nosotros/">Sobre Nosotros</a>
                  </li>
                  <li>
                    <a href="https://mudandote.mx/servicio-de-mudanza-local-en-cdmx/">Servicios</a>
                  </li>
                  <li>
                    <a href="https://mudandote.mx/contacto/">Contacto</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="float_down">
              <div className="row w-100 m-0">
                <div className="col-12">
                    <ul className="social_list">
                      <li>
                        <a href="https://www.facebook.com/TMLMexico" target="_blank">
                          <FacebookIcon />
                        </a>
                      </li>
                    </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row h-100 align-items-center">
          <div className="col-12 col-lg-6 h-100 bg-primary container_one">
              <div>
                <div className="row w-100 h-100 m-0 px-5 align-items-center">
                  <div className="col-12 p-0 position-relative">
                    <img src={logo_white} alt="" />
                    <ul className="social_list">
                      <li>
                        <a href="https://www.facebook.com/TMLMexico" target="_blank">
                          <FacebookIcon />
                        </a>
                      </li>
                    </ul>
                    <button className="menu" onClick={() => { setShowMenu(true); }}>
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
              <div>
                <div className="row h-100 align-items-center justify-content-center">
                  {step===1?
                    <div className="col-12 col-xl-11 text-center content-step">
                      <div className="block_ctrl">
                        <img className="centered_img" src={step_one} alt=""/>
                      </div>
                      <span className="title_ mb-2">Bienvenido ¿quieres cotizar?</span>
                    </div>
                  :''}
                  {step===2?
                    <div className="col-12 col-xl-11 text-center content-step">
                      <div className="block_ctrl">
                        <img className="centered_img" src={step_two} alt=""/>
                      </div>
                      <span className="title_ mb-2">¿A dónde te mudas?</span>
                    </div>
                  :''}
                  {step===3?
                    <div className="col-12 col-xl-11 text-center content-step">
                      <span className="title_ mb-2">¿Qué llevarás?</span>
                      <div className="block_ctrl container_items_overflow">
                        <div className="row w-100 m-0 justify-content-start justify-content-xl-center">
                          <PasoTres01 propSetCategoria={setCategoria} propCategoria={categoria}/>
                        </div>
                      </div>
                    </div>
                  :''}
                  {step===4?
                    <div className="col-12 col-xl-11 text-center content-step">
                      <div className="block_ctrl">
                        <img className="centered_img" src={step_three} alt=""/>
                      </div>
                      <span className="title_ mb-2">Te mostramos tu resumen</span>
                      <span className="complement_">Verifica tus datos, si algo esta mal usa el boton "Anterior" para regresar a hacer las correcciones necesarias.</span>
                    </div>
                  :''}
                  {step===5?
                    <div className="col-12 col-xl-11 text-center content-step">
                      <div className="block_ctrl">
                        <img className="centered_img" src={step_five} alt=""/>
                      </div>
                      <span className="title_ mb-2">¡Esta es tu cotización!</span>
                      <span className="complement_">Te hemos enviado una copia de la cotización a tu correo electrónico.</span>
                    </div>
                  :''}
                </div>
              </div>
              <div>
                <div className="row h-100 steps m-0">
                  <div className="col h-100">
                    <button className={"row m-0 w-100 h-100 align-items-center " + (step===1 ? 'active' : '')}>
                      <div className="col-12 p-0">
                        <img src={icon_1} alt=""/>
                        <span>Datos</span>
                      </div>
                    </button>
                  </div>
                  <div className="col h-100">
                    <button className={"row m-0 w-100 h-100 align-items-center " + (step===2 ? 'active' : '')}>
                      <div className="col-12 p-0">
                        <img src={icon_2} alt=""/>
                        <span>Destino</span>
                      </div>
                    </button>
                  </div>
                  <div className="col h-100">
                    <button className={"row m-0 w-100 h-100 align-items-center " + (step===3 ? 'active' : '')}>
                      <div className="col-12 p-0">
                        <img src={icon_3} alt=""/>
                        <span>Inventario</span>
                      </div>
                    </button>
                  </div>
                  <div className="col h-100">
                    <button className={"row m-0 w-100 h-100 align-items-center " + (step===4 ? 'active' : '')}>
                      <div className="col-12 p-0">
                        <img src={icon_4} alt=""/>
                        <span>Resumen</span>
                      </div>
                    </button>
                  </div>
                  <div className="col h-100">
                    <button className={"row m-0 w-100 h-100 align-items-center " + (step===5 ? 'active' : '')}>
                      <div className="col-12 p-0">
                        <img src={icon_5} alt=""/>
                        <span>Cotización</span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
          </div>
          <div className="col-12 col-lg-6 h-100 container_two">

            <div className="row w-100 h-100 align-items-center justify-content-center overflow_y">
              <div>
                <ul>
                  <li>
                    <a href="https://mudandote.mx">Inicio</a>
                  </li>
                  <li>
                    <a href="https://mudandote.mx/nosotros/">Sobre Nosotros</a>
                  </li>
                  <li>
                    <a href="https://mudandote.mx/servicio-de-mudanza-local-en-cdmx/">Servicios</a>
                  </li>
                  <li>
                    <a href="https://mudandote.mx/contacto/">Contacto</a>
                  </li>
                </ul>
              </div>

              {step===1?
                <PasoUno propNextStep={nextStep} propDataCotizacion={dataCotizacion} propSetDataCotizacion={setDataCotizacion} />
              :''}

              {step===2?
                <PasoDos propNextStep={nextStep} propPrevStep={prevStep} propSetOrigenDestino={setOrigenDestino} propSetDistancia={setDistancia} />
              :''}

              {step===3 && categoria!==false?
                <PasoTres02 propNextStep={nextStep} propPrevStep={prevStep} propCategoria={categoria} propSetInventario={setInventario} propSetTotalMts={setTotalMts}/>
              :''}

              {step===4?
                <PasoCuatro propNextStep={nextStep} propPrevStep={prevStep} propInventario={inventario} propDataCotizacion={dataCotizacion} propDistancia={distancia} propOrigenDestino={origenDestino} propTotalMts={totalMts} propSetQuote={setQuote} />
              :''}

              {step===5?
                <PasoCinco propQuote={quote}/>
              :''}
              
            </div>

          </div>
        </div>
      </div>
  );
};

export default Cotizar;