import { axiosApiInstanceHeaders } from './ManageToken'
import { showToastr, toastrError } from '../utils/toast';

function generarRecoverLink(object_data){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/recoverlink`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Email de reuperación enviado', 'Revisa tu bandeja de entrada.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

function verifyCodeRecoverPassword(code){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/verifyCodeChangePassword/`+code
    )
    .then(response => {
      if (response.status!==200) {
          showToastr('success', 'Link invalido', 'El link ha expirado, solicita otro.', 3500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function cambiarContrasenaPublic(object_data, code){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/user/changePassword/`+code,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Contraseña cambiada', 'Inicia sesión con tu nueva contraseña.', 4500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

function publicCategorias(){
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/categorias`
    )
    .then(response => {
      if (response.status===201) {
        return response;
      }
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

function publicInventarioFromCategoria(id){
  return axiosApiInstanceHeaders.get(
      `${process.env.REACT_APP_API_URL}public/categoria/`+id+`/inventario`
    )
    .then(response => {
      if (response.status===200) {
        return response;
      }
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

function cotizar(inventario,dataCotizacion,distancia,origenDestino){
  var formData = new FormData();
  for (const item in dataCotizacion) {
    formData.append(item, dataCotizacion[item]);
  }
  for (const item in origenDestino) {
    formData.append(item, origenDestino[item]);
  }
  let inventario_array = [];
  for (const item_inventario in inventario) {
    var temp_array = {
      'id_inventario': item_inventario.replace('input-', ''),
      'qty': inventario[item_inventario]
    }
    inventario_array.push(temp_array);
  }
  formData.append('inventario', JSON.stringify(inventario_array));
  formData.append('distancia', distancia);
  return axiosApiInstanceHeaders.post(
      `${process.env.REACT_APP_API_URL}public/cotizar`,
      formData
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Cotización realizada', 'Recibiras un email con una copia.', 4500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    }); 
}

export const publicServices = {
  generarRecoverLink,
  verifyCodeRecoverPassword,
  cambiarContrasenaPublic,
  publicCategorias,
  publicInventarioFromCategoria,
  cotizar
};
