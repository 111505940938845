import React from 'react';
import { Outlet } from 'react-router-dom';
import "../../styles/argon_theme/vendor/nucleo/css/nucleo.css";
import "../../styles/argon_theme/vendor/font-awesome/css/font-awesome.min.css";
import "../../styles/argon_theme/scss/argon-design-system-react.scss?v1.1.0";

class PublicLayout extends React.Component {
  render() {
    return (
      <React.Fragment>
          <Outlet />
      </React.Fragment>
    );
  }
}

export default PublicLayout;