import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { authServices } from '../../services/authServices';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  
  const classes = useStyles();
  const navigate = useNavigate();
  
  const [disable, setDisable] = useState(false);

  const actionsLogin = (response) => {
    if (response.status === 201) {
      navigate('/a/mi-perfil', { replace: true }); 
    }
  }

  return (
    <Page
      className={classes.root}
      title="Login"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              user: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              user: Yup.string().max(255).required('Usuario o email requerido'),
              password: Yup.string().max(255).required('La contraseña es requerida')
            })}
            onSubmit={(values) => {
              setDisable(true);
              authServices.login(values)
                .then(response => {
                  actionsLogin(response);
                })
                .catch(function (error) {
                }).then(function () {
                  setDisable(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Inicia sesión
                  </Typography>
                </Box>
                <Box
                  mt={3}
                  mb={1}
                >
                </Box>
                <TextField
                  autoFocus={true}
                  error={Boolean(touched.user && errors.user)}
                  fullWidth
                  helperText={touched.user && errors.user}
                  label="Usuario o email"
                  margin="normal"
                  name="user"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.user}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={disable}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    ENTRAR
                  </Button>
                </Box>
                <Typography
                  color="textSecondary"
                  variant="body1"
                  align="center"
                >
                  <Link
                    component={RouterLink}
                    to="/auth/olvide-mi-contrasena"
                    variant="h6"
                  >
                    ¿Has olvidado la contraseña?
                  </Link>
                </Typography>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
