import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { logedServices } from '../../services/logedServices';
import * as Yup from 'yup';
import { Formik } from 'formik';

const Results = ({ className, ...rest }) => {
  const [selectedQuotesIds, setSelectedQuotesIds] = useState([]);
  const [quotes, setQuotes] = useState(null);
  const [quoteActiva, setQuoteActiva] = useState(null);
  const [disabled, setDisabled] = useState(false);
  
  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  function handleRemove(id) {
    const newList = quotes.data.filter((item) => {
      if (parseInt(item.id) !== parseInt(id)) {
        return item;
      }
    });
    const newArray = quotes;
    newArray['data']=newList;
    setQuotes(newArray);
  }

  function confimDelete(){
      logedServices.deleteQuote(quoteActiva.id)
      .then(response => {
        if (response.status===200) {
          handleRemove(response.data.id);
          setOpen(false);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  useEffect(() => {
      getQuotes(1);
  }, []);

  function getQuotes(page){
      logedServices.getQuotes(page, 10)
      .then(response => {
        if (response.status===200) {
          setQuotes(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedQuotesIds;

    if (event.target.checked) {
      newSelectedQuotesIds = quotes.data.map((inventario) => inventario.id);
    } else {
      newSelectedQuotesIds = [];
    }

    setSelectedQuotesIds(newSelectedQuotesIds);
    console.log('newSelectedQuotesIds==>')
    console.log(newSelectedQuotesIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedQuotesIds.indexOf(id);
    let newSelectedQuotesIds = [];

    if (selectedIndex === -1) {
      newSelectedQuotesIds = newSelectedQuotesIds.concat(selectedQuotesIds, id);
    } else if (selectedIndex === 0) {
      newSelectedQuotesIds = newSelectedQuotesIds.concat(selectedQuotesIds.slice(1));
    } else if (selectedIndex === selectedQuotesIds.length - 1) {
      newSelectedQuotesIds = newSelectedQuotesIds.concat(selectedQuotesIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedQuotesIds = newSelectedQuotesIds.concat(
        selectedQuotesIds.slice(0, selectedIndex),
        selectedQuotesIds.slice(selectedIndex + 1)
      );
    }

    setSelectedQuotesIds(newSelectedQuotesIds);
  };

  const handlePageChange = (event, newPage) => {
    getQuotes(newPage);
  };

  return (
    <React.Fragment>
      <Card>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <CardHeader
              title="Cotizaciones"
            />
          </Grid>
          {quotes!==null && quotes.data.length>0 ? 
          <Grid
            item
            xs={12}
          >
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <PerfectScrollbar>
                    <Box minWidth={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedQuotesIds.length === quotes.data.length}
                                color="primary"
                                indeterminate={
                                  selectedQuotesIds.length > 0
                                  && selectedQuotesIds.length < quotes.data.length
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell align="center">
                              Id
                            </TableCell>
                            <TableCell align="center">
                              Cliente
                            </TableCell>
                            <TableCell align="center">
                              Distancia
                            </TableCell>
                            <TableCell align="center">
                              M<sup>2</sup>
                            </TableCell>
                            <TableCell align="center">
                              Fecha mudanza
                            </TableCell>
                            <TableCell align="center">
                              Fecha cotización
                            </TableCell>
                            <TableCell align="center">
                              PDF
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {quotes.data.map((quote_) => (
                            <TableRow
                              hover
                              key={quote_.id}
                              selected={selectedQuotesIds.indexOf(quote_.id) !== -1}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedQuotesIds.indexOf(quote_.id) !== -1}
                                  onChange={(event) => handleSelectOne(event, quote_.id)}
                                  value="true"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {quote_.idpublic}
                              </TableCell>
                              <TableCell align="center">
                                {quote_.nombre}
                              </TableCell>
                              <TableCell align="center">
                                {quote_.distancia}
                              </TableCell>
                              <TableCell align="center">
                                {quote_.mts}
                              </TableCell>
                              <TableCell align="center">
                                {quote_.fecha}
                              </TableCell>
                              <TableCell align="center">
                                {quote_.created_at}
                              </TableCell>
                              <TableCell align="center">
                                <a
                                  href={`${process.env.REACT_APP_API_URL}`+'storage/quote_'+quote_.id+'.pdf'}
                                  target="_blank"
                                >
                                  Link
                                </a>
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  <Button component={RouterLink} to={'/a/cotizacion/'+quote_.id}>Ver más</Button>
                                  <Button onClick={() => { setQuoteActiva(quote_);setOpen(true); }}>Eliminar</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid container justifyContent="flex-end">
                    <Pagination count={quotes.meta.last_page} page={quotes.meta.current_page} onChange={handlePageChange} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          : ''}
        </Grid>
      </Card>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se eliminará la cotización '{quoteActiva!==null ? quoteActiva.idpublic : ''}''
            <strong className="block_ctrl">¿Deseas continuar?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { confimDelete(); }} color="primary">
            Si, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
