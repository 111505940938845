import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { logedServices } from '../../services/logedServices';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ImageUploader from 'react-images-upload';
import { axiosApiInstanceFormData } from '../../services/ManageToken'
import { showToastr, toastrError } from '../../utils/toast';
import Loader from '../utilities/loader';

const Results = ({ className, ...rest }) => {
  const [selectedCategoriasIds, setSelectedCategoriasIds] = useState([]);
  const [categorias, setCategorias] = useState(null);
  const [categoriaActiva, setCategoriaActiva] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState([]);
  const [progress, setProgress] = useState(null);
  const [errorRequire, setErrorRequire] = useState(false);
  
  function onDrop(picture) {
    setFile(picture);
    setErrorRequire(false);
  }

  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };


  function handleRemove(id) {
    const newList = categorias.data.filter((item) => {
      if (parseInt(item.id) !== parseInt(id)) {
        return item;
      }
    });
    const newArray = categorias;
    newArray['data']=newList;
    setCategorias(newArray);
  }

  function confimDelete(){
      logedServices.deleteCategoria(categoriaActiva.id)
      .then(response => {
        if (response.status===200) {
          handleRemove(response.data.id);
          setOpen(false);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  useEffect(() => {
      getCategorias(1);
  }, []);

  function getCategorias(page){
      logedServices.getCategorias(page, 10)
      .then(response => {
        if (response.status===200) {
          setCategorias(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedCategoriasIds;

    if (event.target.checked) {
      newSelectedCategoriasIds = categorias.data.map((categoria) => categoria.id);
    } else {
      newSelectedCategoriasIds = [];
    }

    setSelectedCategoriasIds(newSelectedCategoriasIds);
    console.log('newSelectedCategoriasIds==>')
    console.log(newSelectedCategoriasIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCategoriasIds.indexOf(id);
    let newSelectedCategoriasIds = [];

    if (selectedIndex === -1) {
      newSelectedCategoriasIds = newSelectedCategoriasIds.concat(selectedCategoriasIds, id);
    } else if (selectedIndex === 0) {
      newSelectedCategoriasIds = newSelectedCategoriasIds.concat(selectedCategoriasIds.slice(1));
    } else if (selectedIndex === selectedCategoriasIds.length - 1) {
      newSelectedCategoriasIds = newSelectedCategoriasIds.concat(selectedCategoriasIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedCategoriasIds = newSelectedCategoriasIds.concat(
        selectedCategoriasIds.slice(0, selectedIndex),
        selectedCategoriasIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCategoriasIds(newSelectedCategoriasIds);
  };

  const handlePageChange = (event, newPage) => {
    getCategorias(newPage);
  };

  return (
    <React.Fragment>
      <Card>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <CardHeader
              title="Categorías"
            />
            <Grid container justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{marginRight: "15px", marginBottom: "15px"}}
                onClick={() => { setOpenAdd(true); }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          {categorias!==null && categorias.data.length>0 ? 
          <Grid
            item
            xs={12}
          >
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <PerfectScrollbar>
                    <Box minWidth={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedCategoriasIds.length === categorias.data.length}
                                color="primary"
                                indeterminate={
                                  selectedCategoriasIds.length > 0
                                  && selectedCategoriasIds.length < categorias.data.length
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell align="center">
                              Nombre
                            </TableCell>
                            <TableCell align="center">
                              Imagen
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {categorias.data.map((categoria) => (
                            <TableRow
                              hover
                              key={categoria.id}
                              selected={selectedCategoriasIds.indexOf(categoria.id) !== -1}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedCategoriasIds.indexOf(categoria.id) !== -1}
                                  onChange={(event) => handleSelectOne(event, categoria.id)}
                                  value="true"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {categoria.nombre}
                              </TableCell>
                              <TableCell align="center" style={{ backgroundColor:'#efefef' }}>
                                  <img style={{ width: 115 }} src={`${process.env.REACT_APP_API_URL}`+categoria.imagen} alt={categoria.nombre} />
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  <Button onClick={() => { setCategoriaActiva(categoria);setOpenUpdate(true); }}>Editar</Button>
                                  <Button onClick={() => { setCategoriaActiva(categoria);setOpen(true); }}>Eliminar</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid container justifyContent="flex-end">
                    <Pagination count={categorias.meta.last_page} page={categorias.meta.current_page} onChange={handlePageChange} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          : ''}
        </Grid>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se eliminara la categoría '{categoriaActiva!==null ? categoriaActiva.nombre : ''}''
            <strong className="block_ctrl">¿Deseas continuar?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { confimDelete(); }} color="primary">
            Si, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAdd} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
        {progress===null?'':
          <Loader value={progress}/>
        }
        <Formik
          initialValues={{
            nombre: '',
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
          })}
          onSubmit={(values) => {
            if (file.length===0) {
              setErrorRequire(true);
              return false;
            }
            setDisabled(true);
            var formData = new FormData(); 
            formData.append('file', file[0], file[0].name);
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/categorias`,
                formData,
                {
                  onUploadProgress: progressEvent => {
                    let prog=Math.round(progressEvent.loaded / progressEvent.total * 100);
                    if (prog!==100) {
                      setProgress(prog);
                    }
                  }
                }
              )
              .then(response => {
                if (response.status===201) {
                    showToastr('success', 'Correcto.', 'Has agregado una nueva categoría.', 2500, true, true, 'top-right');
                    setProgress(100);
                    getCategorias(1);
                    setOpenAdd(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setProgress(null);
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Nueva categoría"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <ImageUploader
                              withIcon={true}
                              buttonText='Choose images'
                              onChange={onDrop}
                              imgExtension={['.jpg', '.jpeg', '.png', '.webp', '.svg']}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                              className={errorRequire?'error-require_image':''}
                            />
                            {errorRequire ?
                              <span className="custom_error block_ctrl text-center">Agrega una imagen</span>
                            :''}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAdd} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Agregar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>


      <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            nombre: categoriaActiva!==null ? categoriaActiva.nombre : '',
            id: categoriaActiva!==null ? categoriaActiva.id : ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData();
            if (file.length>0) {
              formData.append('file', file[0], file[0].name);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/categorias/update`,
                formData
              )
              .then(response => {
                if (response.status===200) {
                    showToastr('success', 'Correcto.', 'La categoría ha sido actualizada.', 2500, true, true, 'top-right');
                    getCategorias(1);
                    setOpenUpdate(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Actualizando categoría"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <ImageUploader
                              withIcon={true}
                              defaultImages = {[
                                `${process.env.REACT_APP_API_URL}`+categoriaActiva.imagen
                              ]}

                              buttonText='Choose images'
                              onChange={onDrop}
                              imgExtension={['.jpg', '.jpeg', '.png', '.webp', '.svg']}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                              className={errorRequire?'error-require_image':''}
                            />
                            {errorRequire ?
                              <span className="custom_error block_ctrl text-center">Agrega una imagen</span>
                            :''}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUpdate} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Actualizar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>



    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
