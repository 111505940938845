import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import LoginView from 'src/views/auth/LoginView';
import OlvideContrasena from 'src/views/auth/OlvideContrasena';
import CambiaContrasena from 'src/views/auth/CambiaContrasena';
import CodeSent from 'src/views/auth/CodeSent';
import ValidarCode from 'src/views/auth/ValidarCode';
import NotFoundView from 'src/views/errors/NotFoundView';
import AccountView from 'src/views/account/AccountView';
import Categorias from 'src/views/categorias';
import Inventario from 'src/views/inventario';
import Cotizar from 'src/views/cotizar';
import Cotizaciones from 'src/views/cotizaciones';
import Cotizacion from 'src/views/cotizaciones/cotizacion';

const routes = [
  {
    path: '/a',
    element: <DashboardLayout propType='admin' />,
    children: [
      { path: 'mi-perfil', element: <AccountView propType='admin' /> },
      { path: 'categorias', element: <Categorias/> },
      { path: 'inventario', element: <Inventario/> },
      { path: 'cotizaciones', element: <Cotizaciones/> },
      { path: 'cotizacion/:id', element: <Cotizacion/> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/auth',
    element: <MainLayout />,
    children: [
      { path: '/', element: <LoginView /> },
      { path: '/login', element: <LoginView /> },
      { path: 'olvide-mi-contrasena', element: <OlvideContrasena /> },
      { path: 'cambia-tu-contrasena/:code', element: <CambiaContrasena /> },
      { path: 'codigo-enviado/:email', element: <CodeSent /> },
      { path: 'validar/:code', element: <ValidarCode /> },
      { path: '404', element: <NotFoundView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      { path: '/', element: <Cotizar /> }
    ]
  }
];

export default routes;