import React, { useState, useEffect } from 'react';

import {
  CircularProgress
} from '@material-ui/core';
import { publicServices } from '../../services/publicServices';
import PerfectScrollbar from 'react-perfect-scrollbar';

const PasoTres01 = (props) => {

  const setCategoria = props.propSetCategoria;
  const categoria = props.propCategoria;
  const [dataCategorias, setDataCategorias] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      getCategorias();
  }, []);

  function getCategorias(){
      setLoading(true);
      publicServices.publicCategorias()
      .then(response => {
        if (response.status===201) {
          setDataCategorias(response.data.data);
          setCategoria(response.data.data[0]);
        }
      })
      .catch(function (error) {
        return error;
      }).then(function () {
        setLoading(false);
      });
  }

  return (
    <React.Fragment>

      {loading?
        <div className="loading blue">
          <div className="row w-100 h-100 m-0 justify-content-start align-items-center">
            <div className="col-12 text-center">
               <CircularProgress color="secondary" />
            </div>
          </div>
        </div>
      :''}

      {dataCategorias.length>0 ? 
        <React.Fragment>
            {dataCategorias.map((categoria_) => (
                <button className={"col-12 item_cat " + (categoria_===categoria?'active':'')} onClick={(e) => {setCategoria(categoria_);}} key={'cat_'+categoria_.id}>
                  <img src={`${process.env.REACT_APP_API_URL}`+categoria_.imagen} alt=""/>
                  <span>{categoria_.nombre}</span>
                </button>
            ))}
        </React.Fragment>
      : ''}
    </React.Fragment>
  );            
};

export default PasoTres01;