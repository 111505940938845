import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import User from './User';
import ProfileDetails from './ProfileDetails';
import ProfileDetailsCliente from './ProfileDetailsCliente';
import { logedServices } from '../../../services/logedServices';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Account = (props) => {

  const [profile, setProfile] = useState(null);
  const type = props.propType;

  useEffect(() => {
    getUserInSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getUserInSession(){
    logedServices.getUserInSession()
    .then(response => {
      setProfile(response.data);
    })
    .catch(function (error) {
        return error;
    })
  }

  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Account"
    >
      <Container maxWidth="lg">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xs={12}
          >
            <User propProfile={profile} propSetProfile={setProfile}/>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xs={12}
          >
            {type==='admin'?
              <ProfileDetails propProfile={profile} propSetProfile={setProfile}/>
            :
              <ProfileDetailsCliente propProfile={profile} propSetProfile={setProfile}/>
            }            
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Account;
