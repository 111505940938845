import React from 'react';
import logo_white from '../static/imgs/logo_short.svg';

const Logo = (props) => {
  return (
    <img
      alt="Logo"
      src={logo_white}
      {...props}
    />
  );
};

export default Logo;
