import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';
import { publicServices } from '../../services/publicServices';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import validator from 'validator';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CambiaContrasena = () => {
  const classes = useStyles();
  const { code } = useParams();
  const navigate = useNavigate();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    verifyCode(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function verifyCode(code){
    publicServices.verifyCodeRecoverPassword(code)
    .then(response => {
      if (response.status!==200) {
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
        return error;
    })
  }

  return (
    <Page
      className={classes.root}
      title="Cambia tu contraseña"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              password: '',
              repeat_password: '',
            }}
            validationSchema={Yup.object().shape({
              password: Yup.string().max(255).required('La contraseña es requerida').test('test-name', 'La contraseña no cumple las condiciones seguras', 
                function(value) {
                  if (value!==undefined) {
                    if (validator.isStrongPassword(value, {
                      minLength: 8, minLowercase: 1,
                      minUppercase: 1, minNumbers: 1, minSymbols: 1
                    })) {
                      return true;
                    } else {
                      return false;
                    }
                  }
                }),
              repeat_password: Yup.string().required('La contraseña es requerida').oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
            })}
            onSubmit={(values) => {
              setDisable(true);
              publicServices.cambiarContrasenaPublic(values, code)
                .then(response => {
                  if (response.status === 201) {
                    navigate('/auth/login', { replace: true });
                  }
                })
                .catch(function (error) {
                }).then(function () {
                  setDisable(false);
                });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    Cambia tu contraseña
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  label="Contraseña"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                  helperText="Al menos 8 caracteres, 1 minuscula, 1 mayuscula, 1 numero y 1 simbolo."
                />
                <TextField
                  error={Boolean(touched.repeat_password && errors.repeat_password)}
                  fullWidth
                  helperText={touched.repeat_password && errors.repeat_password}
                  label="Repite la contraseña"
                  margin="normal"
                  name="repeat_password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.repeat_password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={disable}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    CAMBIAR
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default CambiaContrasena;
