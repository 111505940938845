import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@material-ui/core';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

const user = {
  avatar: '/static/images/avatars/user.svg',
  jobTitle: localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined ? JSON.parse(localStorage.getItem('user_data')).type : '',
  name: localStorage.getItem('user_data')!==null && localStorage.getItem('user_data')!==undefined ? JSON.parse(localStorage.getItem('user_data')).name : ''
};

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const NavBarAdmin = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={2}
      >
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to="/a/mi-perfil"
        />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>

        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Menu
            </ListSubheader>
          }
          className={classes.root+' custom_list'}
        >

          <ListItem button component={RouterLink} to='/a/cotizaciones'>
            <ListItemIcon>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText className={classes.sub_list_tipography} primary="Cotizaciones" />
          </ListItem>

          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <EventSeatIcon />
            </ListItemIcon>
            <ListItemText primary="Inventario" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={RouterLink} to='/a/inventario'>
                <ListItemIcon>
                  <PlaylistAddIcon />
                </ListItemIcon>
                <ListItemText className={classes.sub_list_tipography} primary="Inventario" />
              </ListItem>
              <ListItem button component={RouterLink} to='/a/categorias'>
                <ListItemIcon>
                  <LibraryAddIcon />
                </ListItemIcon>
                <ListItemText className={classes.sub_list_tipography} primary="Categorías" />
              </ListItem>
            </List>
          </Collapse>




        </List>

      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBarAdmin.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBarAdmin.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBarAdmin;
