import React from 'react';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import {
  TextField,
  Button
} from '@material-ui/core';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InputAdornment from '@material-ui/core/InputAdornment';

const google = window.google;

setLocale({
  string: {
    required: 'Este campo es requerido',
    email: 'No es un email valido',
  },
});

const PasoDos = (props) => {

  const nextStep = props.propNextStep;
  const prevStep = props.propPrevStep;
  const setDistancia = props.propSetDistancia;
  const setOrigenDestino = props.propSetOrigenDestino;

  function haversine_distance(lat_origen, lng_origen, lat_destino, lng_destino) {
        var R = 6371.0710; // Radius of the Earth in kms
        var rlat1 = lat_origen * (Math.PI/180); // Convert degrees to radians
        var rlat2 = lat_destino * (Math.PI/180); // Convert degrees to radians
        var difflat = rlat2-rlat1; // Radian difference (latitudes)
        var difflon = (lng_destino-lng_origen) * (Math.PI/180); // Radian difference (longitudes)
        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
        return d;
  }

function getDistance(lat_origen, lng_origen, lat_destino, lng_destino)
  {
     var distanceService = new google.maps.DistanceMatrixService();
     distanceService.getDistanceMatrix({
        origins: [{lat:lat_origen, lng:lng_origen}],
        destinations: [{lng:lng_destino, lat:lat_destino}],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        durationInTraffic: false,
        avoidHighways: false,
        avoidTolls: false
    },
    function (response, status) {
        if (status !== google.maps.DistanceMatrixStatus.OK) {
            console.log('Error==>', status);
        } else {
          let distancia_ruta = (response.rows[0].elements[0].distance.value)/1000;
          console.log('Distancia ruta===>');
          console.log(distancia_ruta+' kms');
          setDistancia(distancia_ruta);
        }
    });
  }

  return (
    <div className="col-12 col-md-10 col-lg-8">
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-12 px-0">
          <span className="tittle_">
            <span>2/4</span>
            Ingresa tu orígen y destino
          </span>
        </div>
        <div className="col-12 px-0">
          <Formik
            initialValues={{
              origen: '',
              destino: '',
              origen_lat: '',
              origen_long: '',
              destino_lat: '',
              destino_long: ''
            }}
            validationSchema={Yup.object().shape({
              origen: Yup.string().max(255).required(),
              destino: Yup.string().max(255).required(),
              origen_lat: Yup.string().required('La direccion no es valida'),
              origen_long: Yup.string().required('La direccion no es valida'),
              destino_lat: Yup.string().required('La direccion no es valida'),
              destino_long: Yup.string().required('La direccion no es valida')
            })}
            onSubmit={(values) => {
              var dist = haversine_distance(parseFloat(values.origen_lat), parseFloat(values.origen_long), parseFloat(values.destino_lat), parseFloat(values.destino_long));
              console.log('Distancia directa==>');
              console.log(dist+' kms');
              getDistance(parseFloat(values.origen_lat), parseFloat(values.origen_long), parseFloat(values.destino_lat), parseFloat(values.destino_long));
              setOrigenDestino(values);
              nextStep();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <PlacesAutocomplete
                      value={values.origen}
                      onChange={e => {
                        setFieldValue("origen", e);
                        setFieldValue("origen_lat", '');
                        setFieldValue("origen_long", '');
                      }}
                      onSelect={e => {
                        setFieldValue("origen", e);
                        geocodeByAddress(e)
                          .then(results => getLatLng(results[0]))
                          .then(latLng => {
                            setFieldValue("origen_lat", latLng.lat);
                            setFieldValue("origen_long", latLng.lng);
                          })
                          .catch(error => console.error('Error', error));
                      }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <TextField
                            autoFocus={true}
                            error={Boolean((touched.origen && errors.origen) || (touched.origen_lat && errors.origen_lat) || (touched.origen_long && errors.origen_long) )}
                            fullWidth
                            helperText={(touched.origen && errors.origen) || (touched.origen_lat && errors.origen_lat) || (touched.origen_long && errors.origen_long)}
                            label="Orígen"
                            margin="normal"
                            name="origen"
                            onBlur={handleBlur}
                            type="text"
                            value={values.origen}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationOnIcon />
                                </InputAdornment>
                              ),
                            }}
                            {...getInputProps()}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="col-12">
                    <PlacesAutocomplete
                      value={values.destino}
                      onChange={e => {
                        setFieldValue("destino", e);
                        setFieldValue("destino_lat", '');
                        setFieldValue("destino_long", '');
                      }}
                      onSelect={e => {
                        setFieldValue("destino", e);
                        geocodeByAddress(e)
                          .then(results => getLatLng(results[0]))
                          .then(latLng => {
                            setFieldValue("destino_lat", latLng.lat);
                            setFieldValue("destino_long", latLng.lng);
                          })
                          .catch(error => console.error('Error', error));
                      }}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div>
                          <TextField
                            autoFocus={true}
                            error={Boolean((touched.destino && errors.destino) || (touched.destino_lat && errors.destino_lat) || (touched.destino_long && errors.destino_long) )}
                            fullWidth
                            helperText={touched.destino && errors.destino}
                            label="Destino"
                            margin="normal"
                            name="destino"
                            onBlur={handleBlur}
                            type="text"
                            value={values.destino}
                            variant="outlined"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationOnIcon />
                                </InputAdornment>
                              ),
                            }}
                            {...getInputProps()}
                          />
                          <div className="autocomplete-dropdown-container">
                            {loading && <div>Loading...</div>}
                            {suggestions.map(suggestion => {
                              const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="col-12 pt-2 text-center">
                    <Button
                      variant="contained"
                      color="default"
                      size="large"
                      startIcon={<NavigateNextIcon/>}
                      type="button"
                      onClick={(e)=>{prevStep()}}
                      className="mr-3 icon_rotate custom_btn01 prevBtn"
                    >
                      Anterior
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      endIcon={<NavigateNextIcon/>}
                      type="submit"
                      className="custom_btn01 nextBtn"
                    >
                      Siguiente
                    </Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );

};

export default PasoDos;