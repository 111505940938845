import React, { useState, useEffect } from 'react';

import {
  Button,
  CircularProgress
} from '@material-ui/core';

import { 
  FormGroup,
  Input
} from 'reactstrap';

import { publicServices } from '../../services/publicServices';
import PerfectScrollbar from 'react-perfect-scrollbar';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const PasoTres02 = (props) => {
  const categoria = props.propCategoria;
  const [dataInventario, setDataInventario] = useState([]);
  const nextStep = props.propNextStep;
  const prevStep = props.propPrevStep;

  const [error, setError] = useState(false);
  const [objs, setObjs] = useState([]);
  const [metros, setMetros] = useState([]);
  const setInventario = props.propSetInventario;
  const setTotalMts = props.propSetTotalMts;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      if (categoria!==false && categoria!==undefined) {
        getInventario();
      }
  }, [categoria]);

  function getInventario(){
      setLoading(true);
      publicServices.publicInventarioFromCategoria(categoria.id)
      .then(response => {
        if (response.status===200) {
          setDataInventario(response.data.data);
        }
      })
      .catch(function (error) {
        return error;
      }).then(function () {
        setLoading(false);
      });
  }

  const handleChange = (e,mts,id) => {
      var qty=e.target.value;
      setObjs({
        ...objs,
        [e.target.name]: qty
      });
      setMetros({
        ...metros,
        [id]: parseFloat(mts)*parseInt(qty)
      });
      if (qty>0) {
        setError(false);
      }
  };

  function tryNextStep(){
    var n=0;
    for(var clave in objs) {
      if(objs[clave] > 0){
        n++;
      }
    }
    if (n>0) {
      setInventario(objs);
      setTotalMts(metros);
      nextStep();
    }else{
      setError(true);
    }
  }

  return (
    <div className="col-12 col-lg-10 col-lg-8 position-relative">
      {loading?
        <div className="loading">
          <div className="row w-100 h-100 m-0 justify-content-start align-items-center">
            <div className="col-12 text-center">
               <CircularProgress />
            </div>
          </div>
        </div>
      :''}
      <div className="row w-100 mx-0 justify-content-center">
        <div className="col-12 px-0 pb-5">
          <span className="tittle_">
            <span>3/4</span>
            Selecciona los objetos de tu inventario
          </span>
        </div>
        <div className="col-12 px-0">
        {dataInventario.length>0 ? 
          <div className="row">
            <div className="col-12 titles_inventario pb-2 mb-4">
              <div className="row">
                <div className="col-12 col-md-4">
                  Objeto
                </div>
                <div className="col-12 col-md-4">
                  Metros
                </div>
                <div className="col-12 col-md-4">
                  Cantidad
                </div>
              </div>
            </div>
            {dataInventario.map((inventario) => (
                <div className={"col-12 item_invent"} key={'invent_'+inventario.id}>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <span>Objeto</span>
                      <div className="block_ctrl">
                        {inventario.nombre}
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <span>Metros</span>
                      <div className="block_ctrl">
                        {inventario.mts} mts2
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <span>Cantidad</span>
                      <div className="block_ctrl">
                        <FormGroup>
                          <Input
                            type="number"
                            name={'input-'+inventario.id}
                            onChange={(e) => {handleChange(e, inventario.mts, inventario.id)}}
                            placeholder="Cantidad"
                            value={objs.hasOwnProperty('input-'+inventario.id)?objs['input-'+inventario.id]:''}
                            min="0"
                          />
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        : ''}
        </div>
        {error?
        <div className="col-12 py-3 text-center">
          <span className="error">Agrega por lo menos un objeto</span>
        </div>
        :''}
        <div className="col-12 pt-2 text-center">
          <Button
            variant="contained"
            color="default"
            size="large"
            endIcon={<NavigateNextIcon/>}
            type="button"
            onClick={(e)=>{prevStep()}}
            className="mr-3 icon_rotate custom_btn01 prevBtn"
          >
            Anterior
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<NavigateNextIcon/>}
            type="submit"
            onClick={(e)=>{tryNextStep()}}
            className="custom_btn01 nextBtn"
          >
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );            
};

export default PasoTres02;