import React from 'react';
import * as Yup from 'yup';
import { setLocale } from 'yup';
import { Formik } from 'formik';
import {
  TextField,
  Button
} from '@material-ui/core';

import NavigateNextIcon from '@material-ui/icons/NavigateNext';

setLocale({
  string: {
    required: 'Este campo es requerido',
    email: 'No es un email valido',
  },
});

const PasoUno = (props) => {

  const nextStep = props.propNextStep;
  const dataCotizacion = props.propDataCotizacion;
  const setDataCotizacion = props.propSetDataCotizacion;

  return (
      <div className="col-12 col-md-10 col-lg-8">
        <div className="row w-100 mx-0 justify-content-center">
          <div className="col-12 px-0">
            <span className="tittle_">
              <span>1/4</span>
              Ingresa tus datos
            </span>
          </div>
          <div className="col-12 px-0">
            <Formik
              initialValues={{
                nombre: !dataCotizacion?'':dataCotizacion.nombre,
                telefono: !dataCotizacion?'':dataCotizacion.telefono,
                email: !dataCotizacion?'':dataCotizacion.email,
                dd: !dataCotizacion?'':dataCotizacion.dd,
                mm: !dataCotizacion?'':dataCotizacion.mm,
                aaaa: !dataCotizacion?'':dataCotizacion.aaaa,
                number: 'CTZ-'+Math.floor(Math.random() * 1000000000)
              }}
              validationSchema={Yup.object().shape({
                nombre: Yup.string().max(255).required(),
                telefono: Yup.string().max(10).required(),
                email: Yup.string().max(50).email().required(),
                dd: Yup.string().required().test('test-name', 'El formato permitido es de 2 digitos (Ej: 04,11,31)', 
                  function(value) {
                    if (value!==undefined) {
                      if ( value.length === 2 && parseInt(value)>0 && parseInt(value)<32) {
                        return true;
                      }else{
                        return false;
                      }
                    }
                  }
                ),
                mm: Yup.string().required().test('test-name', 'El formato permitido es de 2 digitos (Ej: 02,09,11)', 
                  function(value) {
                    if (value!==undefined) {
                      if ( value.length === 2 && parseInt(value)>0 && parseInt(value)<13) {
                        return true;
                      }else{
                        return false;
                      }
                    }
                  }
                ),
                aaaa: Yup.string().required().test('test-name', 'El formato permitido es de 4 digitos (Ej: 2021,2023)', 
                  function(value) {
                    if (value!==undefined) {
                      if ( value.length === 4 && parseInt(value)>2020 && parseInt(value)<2050) {
                        return true;
                      }else{
                        return false;
                      }
                    }
                  }
                )
              })}
              onSubmit={(values) => {
                setDataCotizacion(values);
                nextStep();
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        autoFocus={true}
                        error={Boolean(touched.nombre && errors.nombre)}
                        fullWidth
                        helperText={touched.nombre && errors.nombre}
                        label="Nombre completo"
                        margin="normal"
                        name="nombre"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.nombre}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        error={Boolean(touched.telefono && errors.telefono)}
                        fullWidth
                        helperText={touched.telefono && errors.telefono}
                        label="Teléfono"
                        margin="normal"
                        name="telefono"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.telefono}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Correo Electrónico"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12 pt-3">
                      <span>
                        Fecha de servicio
                      </span>
                    </div>
                    <div className="col-12 col-md-4">
                      <TextField
                        error={Boolean(touched.dd && errors.dd)}
                        fullWidth
                        helperText={touched.dd && errors.dd}
                        label="Día (dd)"
                        margin="normal"
                        name="dd"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.dd}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <TextField
                        error={Boolean(touched.mm && errors.mm)}
                        fullWidth
                        helperText={touched.mm && errors.mm}
                        label="Mes (mm)"
                        margin="normal"
                        name="mm"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.mm}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <TextField
                        error={Boolean(touched.aaaa && errors.aaaa)}
                        fullWidth
                        helperText={touched.aaaa && errors.aaaa}
                        label="Año (aaaa)"
                        margin="normal"
                        name="aaaa"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.aaaa}
                        variant="outlined"
                      />
                    </div>
                    <div className="col-12 pt-2 text-center">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        endIcon={<NavigateNextIcon/>}
                        type="submit"
                        className="custom_btn01 nextBtn"
                      >
                        Siguiente
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
  );

};

export default PasoUno;