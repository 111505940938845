import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { logedServices } from '../../../services/logedServices';
import { checkRut, prettifyRut } from "react-rut-formatter";

const ProfileDetailsCliente = (props) => {

  const profile_ = props.propProfile;
  const setProfile = props.propSetProfile;
  const [disabled, setDisabled] = useState(false);

  return (
    <React.Fragment>
    {profile_!==null ?
      <Formik
        initialValues={{
          name: profile_.user.name,
          email: profile_.user.email,
          user: profile_.user.user,
          direccion: profile_.direccion,
          organizacion: profile_.organizacion,
          razonSocial: profile_.razonSocial,
          rut: profile_.rut,
          telefono: profile_.telefono,
          cliente_id: profile_.id
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(150),
          email: Yup.string().max(150),
          user: Yup.string().max(50),
          direccion: Yup.string().max(50),
          organizacion: Yup.string().max(50),
          razonSocial: Yup.string().max(50),
          rut: Yup.string().max(16).required().test('test-name', 'El RUT no es valido', 
            function(value) {
              if (value!==undefined) {
                if (checkRut(value)) {
                  return true;
                }
                return false;
              }
            }),
          telefono: Yup.string().max(50),
        })}
        onSubmit={(values) => {
          setDisabled(true);
          logedServices.updateUser(values)
            .then(response => {
              if (response.status === 201) {
                setProfile(response.data);
              }
            })
            .catch(function (error) {
            }).then(function () {
              setDisabled(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            <Card>
              <CardHeader
                title="Mi perfil"
              />
              <Divider />
              <CardHeader
                subheader="Informacion de inicio de sesión"
              />
              <CardContent>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.user && errors.user)}
                        fullWidth
                        helperText={touched.user && errors.user}
                        label="Usuario"
                        margin="normal"
                        name="user"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.user}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardHeader
                subheader="Datos de perfil"
              />
              <CardContent>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label="Nombre"
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.organizacion && errors.organizacion)}
                        fullWidth
                        helperText={touched.organizacion && errors.organizacion}
                        label="Organización"
                        margin="normal"
                        name="organizacion"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.organizacion}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.direccion && errors.direccion)}
                        fullWidth
                        helperText={touched.direccion && errors.direccion}
                        label="Dirección"
                        margin="normal"
                        name="direccion"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.direccion}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.razonSocial && errors.razonSocial)}
                        fullWidth
                        helperText={touched.razonSocial && errors.razonSocial}
                        label="Razon social"
                        margin="normal"
                        name="razonSocial"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.razonSocial}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.rut && errors.rut)}
                        fullWidth
                        helperText={touched.rut && errors.rut}
                        label="RUT"
                        margin="normal"
                        name="rut"
                        onBlur={(event) => {
                          const formatted = prettifyRut(values.rut);
                          setFieldValue("rut", formatted);
                          handleBlur(event);
                        }}
                        onChange={handleChange}
                        value={values.rut}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.telefono && errors.telefono)}
                        fullWidth
                        helperText={touched.telefono && errors.telefono}
                        label="Telefono"
                        margin="normal"
                        name="telefono"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.telefono}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={disabled}
                >
                  Guardar
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    : ''}
    </React.Fragment>
  );
};

export default ProfileDetailsCliente;