import React, { useState, useEffect } from 'react';
import {
  Button,
  CircularProgress
} from '@material-ui/core';
import { publicServices } from '../../services/publicServices';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const PasoCuatro = (props) => {

  const nextStep = props.propNextStep;
  const prevStep = props.propPrevStep;
  const inventario = props.propInventario;
  const dataCotizacion = props.propDataCotizacion;
  const distancia = props.propDistancia;
  const origenDestino = props.propOrigenDestino;
  const mts = props.propTotalMts;
  const setQuote = props.propSetQuote;
  const [loading, setLoading] = useState(false);
  
  var total_metros=0;
  for(var clave in mts) {
    total_metros=total_metros+parseFloat(mts[clave]);
  }

  function cotizar() {
      setLoading(true);
      publicServices.cotizar(inventario,dataCotizacion,distancia,origenDestino)
      .then(response => {
        if (response.status===201) {
          setQuote(response.data);
          nextStep();
        }
      })
      .catch(function (error) {
        return error;
      }).then(function () {
        setLoading(false);
      });
  }

  return (
      <div className="col-12 col-md-10 col-lg-8 position-relative">
        {loading?
          <div className="loading">
            <div className="row w-100 h-100 m-0 justify-content-start align-items-center">
              <div className="col-12 text-center">
                 <CircularProgress />
              </div>
            </div>
          </div>
        :''}
        <div className="row w-100 mx-0 justify-content-center">
          <div className="col-12 px-0 pb-5">
            <span className="tittle_">
              <span>4/4</span>
              Esto es lo que sabemos hasta ahora
            </span>
          </div>
          <div className="col-12 px-0">
            <div className="row w-100 m-0 justify-content-center align-items-center">
              <div className="col-12 col-md-6 item_info">
                <span>Número de cotización</span>
                <span>{dataCotizacion.number}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Fecha de servicio</span>
                <span>{dataCotizacion.dd}/{dataCotizacion.mm}/{dataCotizacion.aaaa}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Nombre</span>
                <span>{dataCotizacion.nombre}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Teléfono</span>
                <span>{dataCotizacion.telefono}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Correo</span>
                <span>{dataCotizacion.email}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Origen</span>
                <span>{origenDestino.origen}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Destino</span>
                <span>{origenDestino.destino}</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Distancia</span>
                <span>{Math.round((distancia + Number.EPSILON) * 100) / 100} Km</span>
              </div>
              <div className="col-12 col-md-6 item_info">
                <span>Metros cuadrados utilizados</span>
                <span>{total_metros} m<sup>2</sup></span>
              </div>
            </div>
          </div>
          <div className="col-12 pt-2 text-center">
            <Button
              variant="contained"
              color="default"
              size="large"
              endIcon={<NavigateNextIcon/>}
              type="button"
              onClick={(e)=>{prevStep()}}
              className="mr-3 icon_rotate custom_btn01 prevBtn"
            >
              Anterior
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              endIcon={<NavigateNextIcon/>}
              type="button"
              onClick={(e)=>{cotizar()}}
              className="custom_btn01 nextBtn"
            >
              Cotizar
            </Button>
          </div>

        </div>
      </div>
  );

};

export default PasoCuatro;